<template>
  <form @submit.prevent="onSubmit">
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="title" 
          :error="$v.form.title.$errors.length > 0"
        >
          Company Name
        </a-label>
        <a-input
          id="title"
          v-model="form.title"
          name="title"
          :error="$v.form.title.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="description" 
          :error="$v.form.description.$errors.length > 0"
        >
          Company Description
        </a-label>
        <a-textarea
          id="description"
          v-model="form.description"
          name="description"
          :error="$v.form.description.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="title" 
          :error="$v.form.title.$errors.length > 0"
        >
          Booth Status
        </a-label>
        <div class="flex justify-start">
          <a-switch
            v-model="form.is_live"
            variant="success"
          />
          <div class="ml-1">
            {{ form.is_live ? 'Live' : 'Hidden' }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="py-4 pb-0 border-t border-gray-200"
    >
      <div class="flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-2/12"
          @click="onSubmit"
        >
          Save
        </a-button>
      </div>
    </div>
  </form>
</template>
<script>
import { error } from '@/utils/helpers';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import { mapActions } from 'vuex';
export default {
  mixins: [VuelidateMixin],
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  emits: ['submit'],
  data: () => ({
    loading: false,
    form: {
      title: '',
      description: '',
      is_live: false
    }
  }),
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50)
      },
      description: {
        maxLength: maxLength(5000)
      }
    }
  },
  watch: {
    exhibitor: {
      immediate: true,
      handler: 'onExhibitorChange'
    }
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['update']),
    onExhibitorChange() {
      if (this.exhibitor) {
        this.form = {
          title: this.exhibitor.title,
          description: this.exhibitor.description,
          is_live: this.exhibitor.live
        };
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;

      try {
        await this.update({ exhibitor: this.exhibitor.slug, form: this.form });
        this.$notify('success', 'Changes Saved.');
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>