<template>
  <form @submit.prevent="onSubmit">
    <a-alert type="info">
      <p class="leading-6">
        <strong>Note!</strong> Please include <i>Full URLs</i> to the website and the social platforms (make sure it starts with http:// or https://).
      </p>
    </a-alert>
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label for="website">
          Website URL
        </a-label>
        <a-input
          id="website"
          v-model="form.website"
          type="url"
          name="website"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label for="facebook">
          Facebook URL
        </a-label>
        <a-input
          id="facebook"
          v-model="form.facebook"
          type="url"
          name="facebook"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label for="instagram">
          Instagram URL
        </a-label>
        <a-input
          id="instagram"
          v-model="form.instagram"
          type="url"
          name="instagram"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label for="twitter">
          Twitter URL
        </a-label>
        <a-input
          id="twitter"
          v-model="form.twitter"
          type="url"
          name="twitter"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label for="youtube">
          Youtube URL
        </a-label>
        <a-input
          id="youtube"
          v-model="form.youtube"
          type="url"
          name="youtube"
        />
      </div>
    </div>
    <div
      class="py-4 pb-0 border-t border-gray-200"
    >
      <div class="flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-2/12"
          @click="onSubmit"
        >
          Save
        </a-button>
      </div>
    </div>
  </form>
</template>
<script>
import { error } from '@/utils/helpers';
import { VuelidateMixin } from '@vuelidate/core';
import { mapActions } from 'vuex';
export default {
  mixins: [VuelidateMixin],
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  emits: ['submit'],
  data: () => ({
    loading: false,
    form: {
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: '',
      website: ''
    }
  }),
  watch: {
    exhibitor: {
      immediate: true,
      handler: 'onExhibitorChange'
    }
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['update']),
    onExhibitorChange() {
      if (this.exhibitor) {
        this.form = {
          facebook: this.exhibitor.facebook,
          instagram: this.exhibitor.instagram,
          twitter: this.exhibitor.twitter,
          youtube: this.exhibitor.youtube,
          website: this.exhibitor.website
        };
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;

      try {
        await this.update({ exhibitor: this.exhibitor.slug, form: this.form });
        this.$notify('success', 'Changes Saved.');
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>