<template>
  <a-card 
    v-if="hasExhibitor"
    class="p-8 pb-2 pt-2"
  >
    <a-tabs
      :active="activeTab"
      @change="onTabChange"
    >
      <a-tab
        name="general"
        title="General"
      >
        <general-tab :exhibitor="exhibitor" />
      </a-tab>
      <a-tab
        name="images"
        title="Images"
      >
        <images-tab :exhibitor="exhibitor" />
      </a-tab>
      <a-tab
        name="social"
        title="Social Links"
      >
        <social-tab :exhibitor="exhibitor" />
      </a-tab>
      <a-tab
        name="contacts"
        title="Contacts"
      >
        <contacts-tab :exhibitor="exhibitor" />
      </a-tab>
      <a-tab
        name="videos"
        title="Videos"
      >
        <videos-tab :exhibitor="exhibitor" />
      </a-tab>
      <a-tab
        name="products"
        title="Products"
      >
        <products-tab :exhibitor="exhibitor" />
      </a-tab>
    </a-tabs>
  </a-card>
</template>

<script>
import { isEmpty } from '@/utils/lodash';
import GeneralTab from '@/components/booth/manage/tabs/general.vue';
import ImagesTab from '@/components/booth/manage/tabs/images.vue';
import SocialTab from '@/components/booth/manage/tabs/social.vue';
import VideosTab from '@/components/booth/manage/tabs/videos.vue';
import ProductsTab from '@/components/booth/manage/tabs/products.vue';
import ContactsTab from '@/components/booth/manage/tabs/contacts.vue';
export default {
  components: {
    ContactsTab,
    GeneralTab,
    ImagesTab,
    SocialTab,
    VideosTab,
    ProductsTab
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    activeTab: 'general'
  }),
  computed: {
    hasExhibitor() {
      return !isEmpty(this.exhibitor);
    }
  },
  methods: {
    onTabChange(tab) {
      this.activeTab = tab.name;
    }
  }
};
</script>