<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <div class="border-b sm:border-r sm:border-b-0 border-gray-200 p-2">
      <image-upload
        title="Company Logo"
        field="logo"
        :image="exhibitor.logo"
        :exhibitor="exhibitor"
      >
        <template #image>
          <logo-image
            v-if="exhibitor.logo"
            :image="exhibitor.logo"
          />
        </template>
        <template #description>
          <p class="leading-5 text-gray-500 text-sm">
            Upload the image that'll be visible as the company logo when visitors view the Virtual Booth.
          </p>
          <p class="leading-5 text-gray-500 text-sm italic">
            Image should preferably be square and with a white background.
          </p>
        </template>
      </image-upload>
    </div>
    <div class="p-2">
      <image-upload
        title="Card Logo"
        field="card_logo"
        :image="exhibitor.card_logo"
        :exhibitor="exhibitor"
      >
        <template #image>
          <logo-image
            v-if="exhibitor.card_logo"
            :image="exhibitor.card_logo"
          />
        </template>
        <template #description>
          <p class="leading-5 text-gray-500 text-sm">
            Upload the image that'll be visible as the company logo when rendering the Virtual Booth Card or Featured Card in the Exhibitors Section. 
          </p>
          <p class="leading-5 text-gray-500 text-sm italic">
            Image should preferably be square and with a white background.
          </p>
        </template>
      </image-upload>
    </div>
    <div class="border-b sm:border-r sm:border-b-0 border-gray-200 p-2">
      <image-upload
        title="Company Booth Banner"
        field="banner"
        :image="exhibitor.banner"
        :exhibitor="exhibitor"
      >
        <template #image>
          <banner-image
            v-if="exhibitor.banner"
            :image="exhibitor.banner"
          />
        </template>
        <template #description>
          <p class="leading-5 text-gray-500 text-sm">
            Upload the image that'll be visible as the company virtual booth banner. Visible in the Virtual Booth Profile Page.
          </p>
          <p class="leading-5 text-gray-500 text-sm italic">
            Image should preferably be rectangular, size should be 1200 x 300
          </p>
        </template>
      </image-upload>
    </div>
    <div class="p-2">
      <image-upload
        title="Card Background"
        field="card_background"
        :image="exhibitor.card_background"
        :exhibitor="exhibitor"
      >
        <template #image>
          <banner-image
            v-if="exhibitor.card_background"
            :image="exhibitor.card_background"
          />
        </template>
        <template #description>
          <p class="leading-5 text-gray-500 text-sm">
            Upload the image that'll be visible as the background for the virtual booth card, displayed on the exhibitors page.
          </p>
          <p class="leading-5 text-gray-500 text-sm">
            Image should preferably be square 600px x 600px. The safety area is 100px from the top and bottom, please keep all important information within that area.
          </p>
        </template>
      </image-upload>
    </div>
    <div class="p-2">
      <h3 class="text-xl leading-6 font-medium my-2">
        Example Card
      </h3>
      <featured-card
        demo
        :exhibitor="exhibitor"
      />
    </div>
  </div>
</template>

<script>
import ImageUpload from '../image-upload.vue';
import LogoImage from '@/components/exhibitors/ui/logo.vue';
import BannerImage from '@/components/exhibitors/ui/banner.vue';
import FeaturedCard from '@/components/exhibitors/cards/featured.vue';
export default {
  components: {
    LogoImage,
    ImageUpload,
    BannerImage,
    FeaturedCard
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  }
};
</script>