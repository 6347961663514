<template>
  <li class="video">
    <div class="flex-1 flex flex-col align-top">
      <div>
        <video-embed :row="video" />
      </div>
      <h3 class="mt-6 text-gray-900 text-sm font-medium p-2">
        {{ video.video }}
      </h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dd class="text-gray-500 text-sm">
          Position: {{ video.order }}
        </dd>
      </dl>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <a
            class="video__action"
            @click="onEdit"
          >
            <a-icon name="pencil" />
            <span class="ml-3">Modify</span>
          </a>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a
            class="video__action"
            @click="onDelete"
          >
            <a-icon name="trash" />
            <span class="ml-3">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import VideoEmbed from '@/components/ui/embed/video.vue';
export default {
  components: {
    VideoEmbed
  },
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  emits: ['delete', 'edit'],
  methods: {
    onDelete() {
      if (confirm('Are you sure you would like to delete this video?')) {
        this.$emit('delete', this.video);
      }
    },
    onEdit() {
      this.$emit('edit', this.video);
    }
  }
};
</script>

<style scoped>
.video {
  @apply col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200;
  &__action {
    @apply relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer;
  }
}
</style>