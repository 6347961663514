<template>
  <a-modal
    open
    :title="title"
    @close="onClose"
  >
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="video" 
          :error="$v.form.video.$errors.length > 0"
        >
          Video URL / Embed Code
        </a-label>
        <a-textarea
          id="video"
          v-model="form.video"
          name="video"
          :error="$v.form.video.$errors.length > 0"
        />
        <p class="text-sm leading-5 text-secondary py-2">
          <strong>Note!</strong> You MUST include a video embed code, Not a URL to the video itself on Youtube or Vimeo. Instructions on how to retrieve the embed code can be found here: 
          <a
            href="https://support.google.com/youtube/answer/171780?hl=en"
            target="_blank"
            class="underline"
          >Youtube</a>  | 
          <a
            href="https://vimeo.zendesk.com/hc/en-us/articles/224969968-Embedding-videos-overview"
            target="_blank"
            class="underline"
          >Vimeo</a> 
        </p>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="order" 
          :error="$v.form.order.$errors.length > 0"
        >
          Video Position
        </a-label>
        <a-input
          id="order"
          v-model="form.order"
          type="number"
          name="order"
          :error="$v.form.order.$errors.length > 0"
        />
      </div>
    </div>
    <template #footer>
      <div class="w-full flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-full sm:w-2/12"
          @click="onSubmit"
        >
          {{ buttonLabel }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { error } from '@/utils/helpers';
import { isEmpty, get } from '@/utils/lodash';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength, numeric } from '@vuelidate/validators';
import { mapActions } from 'vuex';
export default {
  mixins: [VuelidateMixin],
  props: {
    video: {
      type: Object,
      default: () => {}
    },
    exhibitor: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data: () => ({
    loading: false,
    form: {
      video: '',
      order: 1
    }
  }),
  validations: {
    form: {
      video: {
        required,
        minLength: minLength(2)
      },
      order: {
        numeric
      }
    }
  },
  computed: {
    isEditing() {
      return !isEmpty(this.video);
    },
    id() {
      return get(this.video, 'id');
    },
    title() {
      return this.isEditing ? `Editing Video #${this.id}` : 'Create New Video';
    },
    buttonLabel() {
      return this.isEditing ? 'Update' : 'Create';
    }
  },
  watch: {
    video: {
      immediate: true,
      handler: 'onVideoChange'
    }
  },
  methods: {
    ...mapActions('exhibitors/videos', ['create', 'update']),
    onClose() {
      this.$emit('close');
    },
    onVideoChange() {
      if (!isEmpty(this.video)) {
        this.form = {
          video: this.video.video,
          order: this.video.order
        };
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;
      const form = {
        exhibitor: this.exhibitor.slug,
        form: this.form,
        id: this.id
      };

      try {
        if (this.isEditing) {
          await this.update(form);
        } else {
          await this.create(form);
        }
        this.$notify('success', 'Video Saved.');
        this.onClose();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>