<template>
  <div>
    <h4 class="text-lg font-bold pb-2 text-gray-500">
      {{ title }}
    </h4>
    <div class="w-full flex justify-between">
      <div
        class="overflow-hidden p-2 rounded bg-gray-200"
        :class="{'ring-2 ring-white': hasImage}"
      >
        <slot
          v-if="hasImage"
          name="image"
        />
        <a-icon
          v-else
          variant
          :name="icon"
          size="7x"
        />
      </div>
      <div>
        <a-file-upload
          ref="uploader"
          auto
          custom-upload
          class="ml-5"
          accept="image/*"
          :max-file-size="5000000"
          :disabled="loading"
          :loading="loading"
          @uploader="onUploaded"
        >
          <template #label>
            <span>Upload</span>
          </template>
        </a-file-upload>
      </div>
    </div>
    <div class="mt-4">
      <slot name="description" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/utils/lodash';
import Uploader from '@/services/uploader.js';
import { mapActions } from 'vuex';
export default {
  props: {
    exhibitor: {
      type: Object,
      default: () => ({})
    },
    icon: {
      type: String,
      default: 'image'
    },
    title: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: () => {}
    },
    field: {
      type: String,
      default: null
    }
  },
  emits: ['uploaded'],
  data: () => ({
    loading: false
  }),
  computed: {
    hasImage() {
      return !isEmpty(this.image);
    }
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['update']),
    async onUploaded({ files }) {
      let file = files[0] || null;

      if (isEmpty(file)) {
        return;
      }

      this.loading = true;

      try {
        const uploader = new Uploader();
        const key = await uploader.upload(file);
        if (!isEmpty(this.field)) {
          await this.update({
            exhibitor: this.exhibitor.slug,
            form: { [this.field]: key }
          });
        }
        this.$emit('uploaded', key);
      } catch (e) {
        this.$notify('danger', 'Failed Uploading Image.');
        console.error(e);
      } finally {
        this.loading = false;
        this.$refs.uploader.clear();
      }
    }
  }
};
</script>