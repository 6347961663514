<template>
  <div>
    <div class="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2 sm:mb-0 border-b border-gray-200 pb-2">
      <div class="w-full sm:w-8/12" />
      <div class="w-full sm:w-4/12">
        <a-button
          size="sm"
          class="w-full"
          @click="onCreate"
        >
          <a-icon
            name="plus"
            class="mr-1"
          />
          Add Product
        </a-button>
      </div>
    </div>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
      <product-card
        v-for="product in products"
        :key="product.id"
        :product="product"
        @edit="onEditProduct"
        @delete="onDeleteProduct"
      />
    </ul>
    <form-modal
      v-if="modalVisible"
      :product="currentProduct"
      :exhibitor="exhibitor"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import { error } from '@/utils/helpers';
import ProductCard from '@/components/booth/manage/products/card.vue';
import FormModal from '@/components/booth/manage/products/form.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    FormModal,
    ProductCard
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalVisible: false,
    currentProduct: {}
  }),
  computed: {
    products() {
      return this.exhibitor.products;
    }
  },
  methods: {
    ...mapActions('exhibitors/products', ['delete']),
    async onDeleteProduct(product) {
      try {
        await this.delete({ exhibitor: this.exhibitor.slug, id: product.id });
      } catch (e) {
        this.$notify('danger', error(e));
      }
    },
    onEditProduct(product) {
      this.currentProduct = product;
      this.modalVisible = true;
    },
    onCreate() {
      this.currentProduct = {};
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
      this.currentProduct = {};
    }
  }
};
</script>