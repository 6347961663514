<template>
  <virtual-booth
    v-if="loaded"
    :exhibitor="exhibitor"
  />
</template>

<script>
import VirtualBooth from '@/components/booth/booth.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    VirtualBooth
  },
  computed: {
    ...mapGetters('exhibitors/exhibitor', ['exhibitor', 'loaded'])
  }
};
</script>