<template>
  <a-modal
    open
    :title="title"
    @close="onClose"
  >
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="name" 
          :error="$v.form.name.$errors.length > 0"
        >
          Name
        </a-label>
        <a-input
          id="name"
          v-model="form.name"
          name="name"
          :error="$v.form.name.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="email" 
          :error="$v.form.email.$errors.length > 0"
        >
          Email Address
        </a-label>
        <a-input
          id="email"
          v-model="form.email"
          type="email"
          name="email"
          :error="$v.form.email.$errors.length > 0"
        />
        <p class="text-gray-300 text-sm">
          Please enter a valid email address. all lower case.
        </p>
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="title" 
          :error="$v.form.title.$errors.length > 0"
        >
          Title
        </a-label>
        <a-input
          id="title"
          v-model="form.title"
          name="title"
          :error="$v.form.title.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <image-upload
          title="Contact Avatar"
          :image="form.image"
          icon="user"
          @uploaded="onImageUploaded"
        >
          <template #image>
            <contact-image
              v-if="form.image"
              :contact="form"
              class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            />
          </template>
          <template #description>
            <p class="leading-5 text-gray-500 text-sm">
              Upload the image that'll be visible as this contacts avatar on the virtual booth page.
            </p>
            <p class="leading-5 text-gray-500 text-sm italic">
              Image should preferably be square and with a white background.
            </p>
          </template>
        </image-upload>
      </div>
    </div>
    <template #footer>
      <div class="w-full flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-full sm:w-2/12"
          @click="onSubmit"
        >
          {{ buttonLabel }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { error } from '@/utils/helpers';
import { isEmpty, get, toLower } from '@/utils/lodash';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
import ImageUpload from '@/components/booth/manage/image-upload.vue';
import { mapActions } from 'vuex';
import ContactImage from './image.vue';
export default {
  components: {
    ContactImage,
    ImageUpload
  },
  mixins: [VuelidateMixin],
  props: {
    contact: {
      type: Object,
      default: () => {}
    },
    exhibitor: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data: () => ({
    loading: false,
    form: {
      name: '',
      title: '',
      initials: '',
      email: '',
      image: {}
    }
  }),
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      title: {},
      image: {}
    }
  },
  computed: {
    isEditing() {
      return !isEmpty(this.contact);
    },
    id() {
      return get(this.contact, 'id');
    },
    name() {
      return get(this.contact, 'name');
    },
    title() {
      return this.isEditing ? this.name : 'Create New Contact';
    },
    buttonLabel() {
      return this.isEditing ? 'Update' : 'Create';
    }
  },
  watch: {
    contact: {
      immediate: true,
      handler: 'onContactChange'
    },
    ['form.email'](value) {
      this.form.email = toLower(value);
    }
  },
  methods: {
    ...mapActions('exhibitors/contacts', ['create', 'update']),
    onClose() {
      this.$emit('close');
    },
    onContactChange() {
      if (!isEmpty(this.contact)) {
        this.form = { ...this.contact };
      }
    },
    onImageUploaded(image) {
      this.form.image = { path: image };
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;
      const form = {
        exhibitor: this.exhibitor.slug,
        form: { ...this.form, image: get(this.form.image, 'path') },
        id: this.id
      };

      try {
        if (this.isEditing) {
          await this.update(form);
        } else {
          await this.create(form);
        }
        this.$notify('success', 'Contact Saved.');
        this.onClose();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>