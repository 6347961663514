<template>
  <li class="product">
    <div class="flex-1 flex flex-col align-top">
      <div class="flex justify-center">
        <product-image
          v-if="image"
          :image="image"
        />
      </div>
      <h3 class="mt-6 text-gray-900 text-xl font-medium p-2">
        {{ product.title }}
      </h3>
      <dl class="mt-1 flex-grow flex flex-col justify-between my-2">
        <dd class="text-primary text-lg font-medium">
          {{ price }}
        </dd>
      </dl>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <a
            class="product__action"
            @click="onEdit"
          >
            <a-icon name="pencil" />
            <span class="ml-3">Modify</span>
          </a>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a
            class="product__action"
            @click="onDelete"
          >
            <a-icon name="trash" />
            <span class="ml-3">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { get, head, find, isEmpty } from '@/utils/lodash';
import { currency } from '@/utils/helpers';
import ProductImage from '@/components/exhibitors/cards/products/image.vue';
export default {
  components: {
    ProductImage
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  emits: ['delete', 'edit'],
  computed: {
    price() {
      return currency(this.product.price);
    },
    images() {
      return get(this.product, 'images', []);
    },
    primary() {
      return find(this.images, image => image.primary);
    },
    first() {
      return head(this.images);
    },
    image() {
      if (!isEmpty(this.primary)) {
        return this.primary.image.path;
      }

      return get(this.first, 'image.path');
    }
  },
  methods: {
    onDelete() {
      if (confirm('Are you sure you would like to delete this product?')) {
        this.$emit('delete', this.product);
      }
    },
    onEdit() {
      this.$emit('edit', this.product);
    }
  }
};
</script>

<style scoped>
.product {
  @apply col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200;
  &__action {
    @apply relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer;
  }
}
</style>