<template>
  <div>
    <div class="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2 mb-2 sm:mb-0 border-b border-gray-200">
      <div class="w-full sm:w-8/12">
        <a-alert type="info">
          <p class="leading-6">
            <strong>Note!</strong> Please include the video embed code. We support all major streaming platforms (Youtube, Vimeo, etc..).
          </p>
        </a-alert>
      </div>
      <div class="w-full sm:w-4/12">
        <a-button
          size="sm"
          class="w-full"
          @click="onCreate"
        >
          <a-icon
            name="plus"
            class="mr-1"
          />
          Add Video
        </a-button>
      </div>
    </div>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
      <video-card
        v-for="video in videos"
        :key="video.id"
        :video="video"
        @edit="onEditVideo"
        @delete="onDeleteVideo"
      />
    </ul>
    <form-modal
      v-if="modalVisible"
      :video="currentVideo"
      :exhibitor="exhibitor"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import { error } from '@/utils/helpers';
import VideoCard from '@/components/booth/manage/videos/card.vue';
import FormModal from '@/components/booth/manage/videos/form.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    VideoCard,
    FormModal
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalVisible: false,
    currentVideo: {}
  }),
  computed: {
    videos() {
      return this.exhibitor.videos;
    }
  },
  methods: {
    ...mapActions('exhibitors/videos', ['delete']),
    async onDeleteVideo(video) {
      try {
        await this.delete({ exhibitor: this.exhibitor.slug, id: video.id });
      } catch (e) {
        this.$notify('danger', error(e));
      }
    },
    onEditVideo(video) {
      this.currentVideo = video;
      this.modalVisible = true;
    },
    onCreate() {
      this.currentVideo = {};
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
      this.currentVideo = {};
    }
  }
};
</script>