<template>
  <div>
    <div class="flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0 sm:space-x-2 mb-2 sm:mb-0 border-b border-gray-200 pb-2">
      <div class="w-full sm:w-8/12" />
      <div class="w-full sm:w-4/12">
        <a-button
          size="sm"
          class="w-full"
          @click="onCreate"
        >
          <a-icon
            name="plus"
            class="mr-1"
          />
          Add Contact
        </a-button>
      </div>
    </div>
    <ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-4">
      <contact-card
        v-for="contact in contacts"
        :key="contact.id"
        :contact="contact"
        @edit="onEditContact"
        @delete="onDeleteContact"
      />
    </ul>
    <form-modal
      v-if="modalVisible"
      :contact="currentContact"
      :exhibitor="exhibitor"
      @close="onCloseModal"
    />
  </div>
</template>

<script>
import { error } from '@/utils/helpers';
import ContactCard from '@/components/booth/manage/contacts/card.vue';
import FormModal from '@/components/booth/manage/contacts/form.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    FormModal,
    ContactCard
  },
  props: {
    exhibitor: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    modalVisible: false,
    currentContact: {}
  }),
  computed: {
    contacts() {
      return this.exhibitor.contacts;
    }
  },
  methods: {
    ...mapActions('exhibitors/contacts', ['delete']),
    async onDeleteContact(contact) {
      try {
        await this.delete({ exhibitor: this.exhibitor.slug, id: contact.id });
      } catch (e) {
        this.$notify('danger', error(e));
      }
    },
    onEditContact(contact) {
      this.currentContact = contact;
      this.modalVisible = true;
    },
    onCreate() {
      this.currentContact = {};
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
      this.currentContact = {};
    }
  }
};
</script>