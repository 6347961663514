<template>
  <div class="w-full max-w-7xl mx-auto">
    <booth-manager :exhibitor="exhibitor" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BoothManager from '@/components/booth/manage/manager.vue';
export default {
  components: {
    BoothManager
  },
  computed: {
    ...mapGetters('exhibitors/exhibitor', ['exhibitor'])
  }
};
</script>