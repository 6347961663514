<template>
  <div class="flex flex-col flex-wrap justify-between space-y-1 border-b border-gray-200 py-2">
    <div class="flex flex-row flex-wrap space-y-1">
      <thumbnail
        v-for="image in images"
        :key="image.id"
        :image="image"
        :allow-delete="!image.primary"
        show-primary
        @delete="onDelete"
        @click="onClick"
      />
    </div>
    <div class="py-2">
      <a-file-upload
        v-if="canUpload"
        ref="uploader"
        auto
        multiple
        custom-upload
        accept="image/*"
        :max-file-size="5000000"
        :disabled="loading"
        :loading="loading"
        @uploader="onUploaded"
      >
        <template #label>
          <span>Upload Image</span>
        </template>
      </a-file-upload>
      <div v-else>
        <p class="text-sm text-secondary leading-5">
          Maximum of  {{ max }} images are allowed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { error, replace } from '@/utils/helpers';
import { isEmpty, has, find, remove, size } from '@/utils/lodash';
import Thumbnail from '@/components/exhibitors/ui/product/thumbnail.vue';
import Uploader from '@/services/uploader.js';
import { mapActions } from 'vuex';
export default {
  components: {
    Thumbnail
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    images: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: 5
    }
  },
  emits: ['change', 'image-upload'],
  data: () => ({
    loading: false
  }),
  computed: {
    reachedLimit() {
      return size(this.images) >= this.max;
    },
    canUpload() {
      return !this.reachedLimit;
    }
  },
  methods: {
    ...mapActions('exhibitors/productImages', ['delete', 'update']),
    async onUploaded({ files }) {
      let file = files[0] || null;

      if (isEmpty(file)) {
        return;
      }

      this.loading = true;

      try {
        const uploader = new Uploader();
        const key = await uploader.upload(file);
        this.$emit('image-upload', key);
      } catch (e) {
        this.$notify('danger', 'Failed Uploading Image.');
      } finally {
        this.loading = false;
        this.$refs.uploader.clear();
      }
    },
    async onDelete(image) {
      if (has(image, 'pending')) {
        this.removePendingImage(image);
        return;
      }

      try {
        const { products } = await this.delete({
          product: this.product.id,
          id: image.id
        });
        this.onComplete(products);
      } catch (e) {
        this.$notify('danger', error(e));
      }
    },
    onComplete(products) {
      const product = find(products, product => product.id === this.product.id);
      this.$emit('change', product.images);
    },
    markPrimary(image) {
      let images = this.images;
      let currentPrimary = find(images, i => i.primary);
      if (currentPrimary) {
        replace(
          images,
          { id: currentPrimary.id },
          { ...currentPrimary, primary: false }
        );
      }
      replace(images, { id: image.id }, { ...image, primary: true });
      this.$emit('change', images);
    },
    removePendingImage(image) {
      let images = this.images;
      remove(images, { id: image.id });
      this.$emit('change', images);
    },
    async onClick(image) {
      if (has(image, 'pending')) {
        this.markPrimary(image);
        return;
      }

      try {
        const { products } = await this.update({
          product: this.product.id,
          id: image.id,
          form: { is_primary: true }
        });
        this.onComplete(products);
      } catch (e) {
        this.$notify('danger', error(e));
      }
    }
  }
};
</script>