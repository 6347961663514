<template>
  <a-modal
    open
    :title="title"
    @close="onClose"
  >
    <div class="mb-4">
      <p class="text-sm leading-5 mb-2">
        NOTE: images must be a file of type: <span class="font-bold">jpeg, jpg, and png</span>; NO pdfs or any other files.
      </p>
      <product-images
        :images="form.images"
        :product="product"
        @change="onImagesChange"
        @image-upload="onImageUpload"
      />
      <p
        v-if="$v.form.images.$errors.length > 0"
        class="text-red-500 leading-5 text-base"
      >
        At least one image is required.
      </p>
    </div>
    <div class="grid grid-cols-6 gap-6 mb-4">
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="title"
          :error="$v.form.title.$errors.length > 0"
        >
          Title
        </a-label>
        <a-input
          id="title"
          v-model="form.title"
          name="title"
          :error="$v.form.title.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="price"
          :error="$v.form.price.$errors.length > 0"
        >
          Price
        </a-label>
        <a-input
          id="price"
          v-model="form.price"
          type="number"
          name="price"
          :error="$v.form.price.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="description"
          :error="$v.form.description.$errors.length > 0"
        >
          Description
        </a-label>
        <a-textarea
          id="description"
          v-model="form.description"
          name="description"
          :error="$v.form.description.$errors.length > 0"
        />
      </div>
      <div class="col-span-6 sm:col-span-3">
        <a-label
          for="order"
          :error="$v.form.order.$errors.length > 0"
        >
          Product Position
        </a-label>
        <a-input
          id="order"
          v-model="form.order"
          type="number"
          name="order"
          :error="$v.form.order.$errors.length > 0"
        />
      </div>
    </div>
    <template #footer>
      <div class="w-full flex justify-end">
        <a-button
          :loading="loading"
          size="sm"
          class="w-full sm:w-2/12"
          @click="onSubmit"
        >
          {{ buttonLabel }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { error, uniqueId } from '@/utils/helpers';
import { isEmpty, get, find, size } from '@/utils/lodash';
import { VuelidateMixin } from '@vuelidate/core';
import { required, minLength, numeric } from '@vuelidate/validators';
import ProductImages from './images.vue';
import { mapActions } from 'vuex';
export default {
  components: {
    ProductImages
  },
  mixins: [VuelidateMixin],
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    exhibitor: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  data: () => ({
    loading: false,
    form: {
      title: '',
      price: '',
      description: '',
      order: 1,
      images: []
    }
  }),
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2)
      },
      price: {
        numeric
      },
      description: {
        minLength: minLength(2)
      },
      order: {
        numeric
      },
      images: {}
    }
  },
  computed: {
    isEditing() {
      return !isEmpty(this.product);
    },
    id() {
      return get(this.product, 'id');
    },
    title() {
      return this.isEditing ? this.product.title : 'Create New Product';
    },
    buttonLabel() {
      return this.isEditing ? 'Update' : 'Create';
    }
  },
  watch: {
    product: {
      immediate: true,
      handler: 'onProductChange'
    }
  },
  methods: {
    ...mapActions('exhibitors/products', ['create', 'update']),
    ...mapActions('exhibitors/productImages', { createImage: 'create' }),
    onClose() {
      this.$emit('close');
    },
    onProductChange() {
      if (!isEmpty(this.product)) {
        this.form = {
          title: this.product.title,
          price: this.product.price,
          description: this.product.description,
          order: this.product.order,
          images: this.product.images
        };
      }
    },
    async onImageUpload(key) {
      if (this.isEditing) {
        await this.createNewImage(key);
      } else {
        this.form.images.push({
          id: uniqueId(),
          image: {
            path: key
          },
          primary: isEmpty(this.form.images) ? true : false,
          order: size(this.form.images) + 1,
          pending: true
        });
      }
    },
    onImagesChange(images) {
      this.form.images = images;
    },
    async createNewImage(key) {
      const form = {
        exhibitor: this.exhibitor.slug,
        product: this.product.id,
        form: {
          image: key
        }
      };
      const { products } = await this.createImage(form);
      const product = find(products, product => product.id === this.product.id);
      this.form.images = product.images;
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.loading = true;
      const form = {
        exhibitor: this.exhibitor.slug,
        form: this.form,
        id: this.id
      };

      try {
        if (this.isEditing) {
          await this.update(form);
        } else {
          await this.create(form);
        }
        this.$notify('success', 'Product Saved.');
        this.onClose();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
