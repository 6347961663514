<template>
  <router-view :key="$route.path" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapGetters('auth', ['booth', 'hasBooth', 'isUser', 'canViewBooth'])
  },
  watch: {
    isUser: {
      immediate: true,
      handler: 'onUserChange'
    },
    booth: {
      immediate: true,
      handler: 'onBoothChange'
    }
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['get']),
    onUserChange() {
      if (this.isUser && !this.canViewBooth) {
        this.$router.replace({
          name: 'dashboard',
          params: { conference: this.current }
        });
      }
    },
    async onBoothChange() {
      if (this.hasBooth) {
        await this.get(this.booth.slug);
      }
    }
  }
};
</script>