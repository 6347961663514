<template>
  <li class="contact">
    <div class="w-full flex items-center justify-between p-6 space-x-6">
      <contact-image
        :contact="contact"
        class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
      />
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="text-gray-900 text-sm font-medium truncate">
            {{ contact.name }}
          </h3>
        </div>
        <p class="mt-1 text-gray-500 text-sm truncate">
          {{ contact.title }}
        </p>
      </div>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <a
            class="contact__action"
            @click="onEdit"
          >
            <a-icon name="pencil" />
            <span class="ml-3">Modify</span>
          </a>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a
            class="contact__action"
            @click="onDelete"
          >
            <a-icon name="trash" />
            <span class="ml-3">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ContactImage from './image.vue';
export default {
  components: {
    ContactImage
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  emits: ['delete', 'edit'],
  methods: {
    onDelete() {
      if (confirm('Are you sure you would like to delete this contact?')) {
        this.$emit('delete', this.contact);
      }
    },
    onEdit() {
      this.$emit('edit', this.contact);
    }
  }
};
</script>

<style scoped>
.contact {
  @apply col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200;
  &__action {
    @apply relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer;
  }
}
</style>