<template>
  <a-avatar
    v-bind="$attrs"
    :source="source"
    round="full"
  >
    <template
      v-if="!hasSource"
      #placeholder
    >
      <slot name="placeholder">
        <span class="text-lg leading-none text-white">{{ initials }}</span>
      </slot>
    </template>
  </a-avatar>
</template>

<script>
import { resize } from '@/utils/resize';
import { get, isEmpty } from '@/utils/lodash';
export default {
  props: {
    contact: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    }
  },
  computed: {
    initials() {
      return this.contact.initials;
    },
    hasSource() {
      return !isEmpty(this.source);
    },
    source() {
      let image = get(this.contact, 'image.path');

      if (!image) {
        return '';
      }

      return resize(image, this.width, this.height);
    }
  }
};
</script>